/**
 * returns an array with moving average of the input array
 * @see https://stackoverflow.com/questions/19981713/html5-js-chart-with-moving-average
 * @param array - the input array
 * @param count - the number of elements to include in the moving average calculation
 * @param qualifier - an optional function that will be called on each value to determine whether it should be used
 */
export function movingAvg(
  array: number[],
  count = 7,
  qualifier?: (val: number) => boolean
): number[] {
  // calculate average for subarray
  const avg = (array: number[], qualifier?: (val: number) => boolean) => {
    let sum = 0,
      count = 0,
      val;
    for (const i in array) {
      val = array[i];
      if (!qualifier || qualifier(val)) {
        sum += val;
        count++;
      }
    }

    return sum / count;
  };

  const result: number[] = [];
  let val;

  // pad beginning of result with 0 values
  for (let i = 0; i < count - 1; i++) result.push(0);

  // calculate average for each subarray and add to result
  for (let i = 0, len = array.length - count; i <= len; i++) {
    val = avg(array.slice(i, i + count), qualifier);
    if (isNaN(val)) result.push(0);
    else result.push(val);
  }

  return result;
}

export function round(data: number[], digits = 0): number[] {
  const factor = 10 ** digits;
  return data.map((value) => Math.round((value || 0) * factor) / factor);
}
