import Vue from "vue";
import VueRouter from "vue-router";
import Nazionale from "../views/Nazionale.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Nazionale",
    component: Nazionale,
  },
  {
    path: "/regioni",
    name: "Regioni",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "regioni" */ "../views/Regioni.vue"),
  },
  {
    path: "/province",
    name: "Provinces",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "province" */ "../views/Province.vue"),
  },
  {
    path: "/tamponi",
    name: "Tests",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tamponi" */ "../views/Tamponi.vue"),
  },
  {
    path: "/andamento",
    name: "Trend",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "andamento" */ "../views/Sorveglianza.vue"),
  },
  {
    path: "/vaccini",
    name: "Vaccini",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "vaccini" */ "../views/Vaccini.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
