import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    nationalData: [],
    regionsData: [],
    provincesData: [],
    regionsVaccineData: [],
    regionsDeliveriesData: [],
  },
});

export default store;
