










import Vue from "vue";
import { Line } from "vue-chartjs";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import "chartjs-plugin-annotation";
import { ChartPluginsOptions, TimeUnit } from "chart.js";
import { AnnotationConfig } from "chartjs-plugin-annotation";

@Component({
  components: {
    LineChartJs: Line,
  },
})
export default class LineChart extends Vue {
  @Prop({ default: {} }) chartdata!: Record<string, []>;
  @Prop({ default: 300 }) height!: number;
  @Prop({ default: 0 }) yLine!: number;
  @Prop({ default: 0 }) xLine!: number;

  @Ref() chart!: Line;

  logarithmic = false;

  @Watch("chartdata")
  onCartDataChanged(): void {
    this.refreshChart();
  }

  mounted(): void {
    this.refreshChart();
  }

  refreshChart(): void {
    this.chart.renderChart(this.chartdata, this.getOptions());
  }

  changeScale(): void {
    this.refreshChart();
  }

  getOptions(): ChartPluginsOptions {
    const annotation: AnnotationConfig = {
      annotations: [],
    };

    if (this.yLine) {
      annotation.annotations.push({
        type: "line",
        drawTime: "beforeDatasetsDraw",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: this.yLine,
        borderColor: "red",
        borderWidth: 1,
      });
    }

    if (this.xLine) {
      annotation.annotations.push({
        type: "line",
        drawTime: "beforeDatasetsDraw",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: this.xLine,
        borderColor: "red",
        borderWidth: 1,
      });
    }

    return {
      plugins: {
        datalabels: {
          display: false,
        },
      },
      annotation: annotation,
      scales: {
        xAxes: [
          {
            display: true,
            type: "time",
            time: {
              unit: "day" as TimeUnit,
              stepSize: 7,
              parser: (date: string) => {
                const regex = /(\d+)\/(\d+)\/(\d+)/;
                const numbers = regex.exec(date);
                if (!numbers) {
                  return;
                }

                return new Date(
                  parseInt(numbers[3], 10),
                  parseInt(numbers[2], 10) - 1,
                  parseInt(numbers[1], 10)
                );
              },
            },
          },
        ],
        yAxes: [
          {
            display: true,
            type: this.logarithmic ? "logarithmic" : "linear",
          },
        ],
      },
    };
  }
}
