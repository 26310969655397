











import Vue from "vue";
import LineChart from "@/components/LineChart.vue";
import Component from "vue-class-component";
import { movingAvg, round } from "@/utils/stats";

interface DayStats {
  data: string;
  stato: string;
  ricoverati_con_sintomi: number;
  terapia_intensiva: number;
  totale_ospedalizzati: number;
  isolamento_domiciliare: number;
  totale_positivi: number;
  variazione_totale_positivi: number;
  nuovi_positivi: number;
  dimessi_guariti: number;
  deceduti: number;
  totale_casi: number;
  tamponi: number;
}

@Component({
  components: {
    LineChart,
  },
})
export default class Nazionale extends Vue {
  private loaded = false;
  private chartdata = {};
  private movingAverage = true;
  private proCivData: DayStats[] = [];

  async mounted(): Promise<void> {
    this.loaded = false;

    if (this.$store.state.nationalData.length) {
      this.init(this.$store.state.nationalData);
      return;
    }

    fetch(
      "https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/dati-json/dpc-covid19-ita-andamento-nazionale.json"
    )
      .then((res) => res.json())
      .then((proCivData: DayStats[]) => {
        this.$store.state.nationalData = proCivData;

        this.init(proCivData);
      });
  }

  private init(proCivData: DayStats[]): void {
    this.proCivData = proCivData;

    this.plot();

    this.loaded = true;
  }

  private plot() {
    const labels: string[] = [];
    let deaths: number[] = [];
    let cases: number[] = [];
    let intensives: number[] = [];
    let positives: number[] = [];
    let news: number[] = [];
    let tests: number[] = [];

    this.proCivData.forEach((dayStats, idx, self) => {
      labels.push(new Date(dayStats.data.substr(0, 10)).toLocaleDateString());
      let yesterdayDeaths = 0;
      if (self[idx - 1]) {
        yesterdayDeaths = self[idx - 1].deceduti;
      }
      deaths.push(dayStats.deceduti - yesterdayDeaths);
      cases.push(dayStats.totale_casi);
      intensives.push(dayStats.terapia_intensiva);
      positives.push(dayStats.totale_positivi);
      news.push(dayStats.nuovi_positivi);
      if (idx === 15) {
        news[news.length - 1] += 600;
      }
      if (idx === 16) {
        news[news.length - 1] -= 600;
      }
      tests.push(dayStats.tamponi);
    });

    if (this.movingAverage) {
      deaths = round(movingAvg(deaths));
      intensives = round(movingAvg(intensives));
      cases = round(movingAvg(cases));
      positives = round(movingAvg(positives));
      news = round(movingAvg(news));
      tests = round(movingAvg(tests));
    }

    this.chartdata = {
      labels,
      datasets: [
        {
          label: "Deceduti",
          backgroundColor: "red",
          borderColor: "red",
          fill: false,
          hidden: true,
          data: deaths,
        },
        {
          label: "Terapia intensiva",
          backgroundColor: "darkorange",
          borderColor: "darkorange",
          fill: false,
          data: intensives,
        },
        {
          label: "Totale casi",
          backgroundColor: "navy",
          borderColor: "navy",
          fill: false,
          hidden: true,
          data: cases,
        },
        {
          label: "Positivi",
          backgroundColor: "orange",
          borderColor: "orange",
          fill: false,
          hidden: true,
          data: positives,
        },
        {
          label: "Nuovi positivi",
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: news,
        },
        {
          label: "Tamponi",
          backgroundColor: "pink",
          borderColor: "pink",
          fill: false,
          hidden: true,
          data: tests,
        },
      ],
    };
  }
}
